import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Footer } from './Components/Footer';
import { Home } from './Components/Home';
import { Route, Routes } from "react-router-dom"; 
import { Navigation } from './Components/Navigation';
import { Faq } from './Components/Faq';
import { Contact } from './Components/Contact';
import { Classes } from './Components/Classes.js';
import { Coaches } from './Components/Coaches.js';
import ScrollButton from './Components/ScrollButton';
import { NotFound } from './Components/NotFound';

function App() {
  ReactDOM.render(
    <BrowserRouter>
        <Navigation />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/classes" element={<Classes />} />
            <Route path="/coaches" element={<Coaches />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        <ScrollButton />
        <div className='padding-spacing'></div>
        <Footer />  
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

export { App };