import { Container } from "react-bootstrap";

import MB from "../images/coaches-profile-pics/markb.jpeg"
import RC from "../images/coaches-profile-pics/raul.jpg"
import GJ from "../images/coaches-profile-pics/glyn.jpg"
import CA from "../images/coaches-profile-pics/carrieann.PNG"

function Coaches(){
    return(
        <>
            <Container className="hero-img-container">
                <div className="hero-image coaches-hero-image" aria-label="Group picture of the New Era Boxing coaching team">
                <div className='main-container'>
                        <div className='content-container'>
                            <div className='hero-sub-images'>
                                <div className="hero-text hero-text-padding">
                                    <h1 className="hero-text-padding">OUR COACHES</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-spacing"></div>

                <div className="card flex-row">
                    <div className="card-header border-0">
                        <img src={MB} alt="Profile picture of lead boxing coach Mark Bebbington" className="coach-profile-pic"/>
                    </div>
                    <div className="card-block px-2">
                        <h4 className="card-title">MARK BEBBINGTON</h4>
                        <p className="card-text">LEAD BOXING COACH</p>
                        <p>I established New Era Community Boxing in 2011 with a focus on high performance coaching whilst maintaining a community club ethos.</p>
                        <p>My main focus is to allow every member, competitor and coach to express themselves whilst at the club, progress and improve both their physical and technical skills. It is my ambition to promote the club both locally and nationally in a positive light and give its members the best opportunity to succeed.</p>
                    </div>
                </div>
                <div className="padding-spacing"></div>
                
                <div className="card flex-row">
                    <div className="card-header border-0">
                        <img src={RC} alt="Profile picture of level 1 boxing coach Raul Comorasu" className="coach-profile-pic"/>
                    </div>
                    <div className="card-block px-2">
                        <h4 className="card-title">RAUL COMORASU</h4>
                        <p className="card-text">LEVEL 1 BOXING COACH</p>
                        <p>As a level 1 boxing coach, my specialities include knowledge on how to help produce competitors and work on specific pointers to bring out the best in peoples abilities.</p>
                        <p>As a coach my experience in the sport includes acting as a corner man for amateur boxer on many occassions, either acting as lead coach or as coaching assistant. I run personalised training sessions for all ages from young children to elderly adults.</p>
                        <p>I also still box competitively and have performed in 41 competitive bouts which allows me to approach training with a boxers perspective.</p>
                    </div>
                </div>
                <div className="padding-spacing"></div>
                
                <div className="card flex-row">
                    <div className="card-header border-0">
                        <img src={CA} alt="Profile picture of level 1 boxing coach Carrie Ann Stevens" className="coach-profile-pic"/>
                    </div>
                    <div className="card-block px-2">
                        <h4 className="card-title">CARRIE ANN STEVENS</h4>
                        <p className="card-text">LEVEL 1 BOXING COACH</p>
                        <p>Carrie Ann is a fully qualified personal trainer with a Diploma in Diet & Nutrition, a level 1 England Boxing qualified coach and is also certified in the "Mind" mental health awareness with a specialism in sport and exercise.</p>
                        <p>Within the club she focuses on creating projects to encourage more people within the community to get involved with exercise. Her projects have a strong focus on improving mental health and relationship with exercise and fitness. To date, Carrie Ann was able to run a collaboration between New Era Boxing, Sport England and Maverick Stars Trust to deliver free boxing classes for people struggling with alcohol misuse and mental health issues.</p>
                    </div>
                </div>
                <div className="padding-spacing"></div>
                
                <div className="card flex-row">
                    <div className="card-header border-0">
                        <img src={GJ} alt="Profile picture of level 1 boxing coach Glyn Jones" className="coach-profile-pic"/>
                    </div>
                    <div className="card-block px-2">
                        <h4 className="card-title">GLYN JONES</h4>
                        <p className="card-text">LEVEL 2 BOXING COACH</p>
                        <p>I have been involved in boxing for over 30 years and certified as a level 2 England boxing coach.</p>
                        <p>I introduced to boxing at Weaverham ABC while I was at school, and gained further experience from many other gyms within the area. I have been involved in many forms of martial arts and also competed in kickboxing at high level of competition.</p>
                        <p>Since Mark opened New Era Boxing I have been assisting with training sessions and have acted as corner man for many of the clubs competitors.</p>
                    </div>
                </div>
                <div className="padding-spacing"></div>
            </Container>
        </>
    )
}

export { Coaches };