import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';

function Faq() {
    return (
        <>
    <Container className='hero-img-container'>
      <div className='padding-spacing'></div>
        <div className="hero-image faqs-hero-image" aria-label='Interior of New Era Boxing club, displaying a variety of boxing equipment'>
                    <div className='main-container'>
                        <div className='content-container'>
                            <div className='hero-sub-images'>
                                <div className="hero-text">
                                    <h1>FAQs</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <p className='padding-spacing'>Thank you for showing interest in the New Era Boxing club. We hope that the information below should cover any questions you may have. If your question has not been answered, please do not hesitate to contact us.</p>
    </Container>
    <Container>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>WHAT IS THE MINIMUM AGE FOR TRAINING?</Accordion.Header>
        <Accordion.Body>
        <p>5 years old for both boys and girls.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>CAN WE JUST TURN UP AND TRAIN?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. Once you have filled out a registration form, anyone can train. You will be supported by experienced and qualified coaches on the skills of boxing.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>DO YOU OFFER PERSONAL TRAINING AT THE CLUB?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. This can be for individuals or small groups based on your requirements.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>IS IT SUITABLE FOR PEOPLE WHO JUST WANT TO IMPROVE THEIR FITNESS?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. We have many members who enjoy the training, learn new boxing skills who don’t compete.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>DO YOU DO SPARRING WITHIN THE SESSIONS?</Accordion.Header>
        <Accordion.Body>
        <p>This is optional. All Junior sessions are none contact for example and any sparring for Adults is directly supervised by an experienced Coach.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>DO YOU PROVIDE EQUIPMENT?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. All equipment is provided. Turn up is sportswear and suitable footwear to train.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>I WANT TO COMPETE, IS THIS POSSIBLE?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. If you are aged between 10 – 40 you can compete if you dedicate yourself to training.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>I AM BRINGING MY SON/DAUGHTER TO TRAINING AT THE CLUB, CAN I STAY AND WATCH THE TRAINING?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. We encourage all parents to support and encourage children at the club and welcome constructive feedback as to make the sessions even better.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>MY SON/DAUGHTER WANTS TO BOX, WHAT ARE YOUR SAFETY PROCEDURES?</Accordion.Header>
        <Accordion.Body>
        <p>All sparring and competitive boxing is directly supervised. For sparring it is led by a qualified coach under controlled conditions. Both boxers wear approved gloves and headguards as well as additional protection such as a gumshield.
        In order to compete a member must first complete an annual medical with an approved Doctor. This is then repeated on the day of each bout with a Doctor and paramedic at the ringside for every bout.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>IS BOXING SAFE?</Accordion.Header>
        <Accordion.Body>
        <p>Olympic-style boxing ranks as the safest amongst all contact sports (such as football and rugby) and amongst other events such as equestrian, motor cycle racing and mountaineering.</p> 
        <p> New era boxing goes to great lengths to protect its athletes. Boxers must wear a mouth guard at all times, as well as protective hand bandages, ‘cup’ protectors and a shirt to absorb sweat. Female boxers are also required to wear breast protectors.
        Boxing gloves are designed and tested to absorb, and not transmit shock under recognised safety guidelines from the International Boxing Association (AIBA) Safety Commission. Boxing referees strive to minimise risk of injury by using eight counts and evaluating the boxers throughout a bout and can dispense counts at anytime (unlike in professional boxing).
        In addition to these stringent safety factors, pre and post bout medicals are required for all boxers each and every time they compete.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header>DO YOU SELL BOXING ITEMS AT THE CLUB?</Accordion.Header>
        <Accordion.Body>
        <p>Yes. We stock basic boxing equipment such as boxing gloves, hand wraps and head guards. We also sell sportswear, such as competition shorts and vests, t-shirts and sports jackets.</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
</Container>
        </>
    )
}

export { Faq };