import { Container } from "react-bootstrap"

function NotFound() {
    return (
        <Container className="content">
            <div>
                <h1>404 error</h1>
                <p>Page not found</p>
            </div>
        </Container>
    )
}

export { NotFound }