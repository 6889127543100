import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CardGroup from 'react-bootstrap/CardGroup';
import Card1 from '../images/card-1.jpg';
import Card2 from '../images/card-2.jpg';
import Card3 from '../images/card-3.jpg';



function HomeCards() {
  return (
    <>
        <Container fluid="lg">
        <Row xs={1} md={1} lg={1} className="g-4">
            <CardGroup lg={1} className='CardGroup padding-spacing' fluid="sm">
                <Card  style={{ width: '100%' }}>
                <Card.Body>
                    <Card.Title>WELCOME TO NEW ERA BOXING</Card.Title>
                    <Card.Text>
                    <div style={{width:'100%'}}><hr className="underline short-underline"></hr></div><br></br>
                        <p>At New Era Boxing Club, we're passionate about helping you achieve your fitness and boxing goals. Based in Northwich, Cheshire, our expert coaches offer classes for both adults and juniors, whether you're a seasoned competitor or just starting out. We pride ourselves on creating a supportive and inclusive community, where everyone can feel welcome and encouraged to succeed. Join New Era Boxing Club today!</p>
                    </Card.Text>
                </Card.Body> 
                </Card>
            </CardGroup>
        </Row>

        <Row>
            <CardGroup className='CardGroup padding-spacing'>
            <Card className="bordered-card" style={{ width: '100%' }}>
                <Card.Img variant="top" src={Card1} aria-label='Main training area, displaying a number of heavy bags used in boxing training'/>
                <Card.Body className='set-card-height'>
                    <Card.Title>JUNIOR CLASSES</Card.Title>
                    <Card.Text>
                    <div style={{width:'100%'}}><hr className="underline"></hr></div><br></br>
                    <p>We offer classes from the age of 5 and above. Our team of coaches are first aid trained and fully DBS checked, so you can rest assured that wellbeing and safeguarding is top priority at New Era Boxing.</p>                    <br></br>
                    <br></br>
                    </Card.Text>
                </Card.Body>
                </Card>
                <Card className="bordered-card" style={{ width: '100%' }}>
                <Card.Img variant="top" src={Card2} aria-label='A pair of boxing gloves lay down in a boxing ring'/>
                <Card.Body>
                    <Card.Title>ADULT CLASSES</Card.Title>
                    <Card.Text>
                    <div style={{width:'100%'}}><hr className="underline"></hr></div><br></br>
                    <p>Led by our team of experienced coaches, the adult sessions provides an intense workout whilst focusing on the fundamentals of boxing. Our classes cater for all skill levels, right the way from beginners to competitive amateur boxers and everything in-between.</p>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className="bordered-card" style={{ width: '100%' }}>
            <Card.Img variant="top" src={Card3} aria-label='Interior of the boxing club, displaying various boxing and exercise equipment'/>
                <Card.Body>
                    <Card.Title>PRIVATE TUITION</Card.Title>
                    <Card.Text>
                    <div style={{width:'100%'}}><hr className="underline"></hr></div><br></br>
                    <p>Through private training sessions, you can benefit from personalised training sessions with our experienced coaches. We offer personal training sessions for individuals and groups. If you are interested in private tuition, please contact us <a href="contact" className="click-here">here</a>.</p>
                    </Card.Text>
                </Card.Body>
                </Card>
            </CardGroup>
            </Row>
        </Container>
    </>
    
  );
}

export default HomeCards;