import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Offcanvas } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../images/new-era-logo.svg';

function Navigation() {
  return (
    <>
{['lg'].map((expand) => (

        <Navbar key={expand} bg="dark" expand={expand} className="mb-3">
        <Container className='header-container'>
          <Navbar.Brand href="/"><img className='logo' src={Logo} aria-label="The New Era Boxing logo"></img></Navbar.Brand>
            <Navbar.Toggle className='justify-content-end' aria-controls={`offcanvasNavbar-expand-${expand}`} aria-label='Navbar toggle icon'/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title className='offcanvas-body' id={`offcanvasNavbarLabel-expand-${expand}`}>
                  NEW ERA BOXING
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">HOME</Nav.Link>
            <Nav.Link href="classes">CLASSES & PRICES</Nav.Link>
            <Nav.Link href="coaches">OUR COACHES</Nav.Link>
            <Nav.Link href="faq">FAQs</Nav.Link>
            <Nav.Link href="contact">CONTACT US</Nav.Link>
                </Nav>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

      ))}
    </>
  );
}

export { Navigation };