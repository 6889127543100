import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HeroImage } from './HeroImage';
import HomeCards from './HomeCards';
import Testimonials from './Testimonials';


function Home() {
  return (
    <>
    <Container className='content'>
    <HeroImage />
    <HomeCards />
    </Container>
    <Testimonials />
    </>
  );
  
}

export {Home};