import { Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { BsArrowUpShort } from "react-icons/bs"

const ScrollButton = () => {
    const [ showGoTop, setShowGoTop ] = useState( false )

const handleVisibleButton = () => {
    setShowGoTop( window.pageYOffset > 50 )
}

const handleScrollUp = () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
}

useEffect( () => {
    window.addEventListener( 'scroll', handleVisibleButton )
}, [] )

var goTopHidden = 'goTopHidden'
var goTop = 'goTop'

return (
    <div className={ showGoTop ? '' : goTopHidden } onClick={ handleScrollUp }>
     <Button variant="primary" className='scrollToTop, goTop__text'><BsArrowUpShort/></Button>

    </div>
)}

export default ScrollButton