import React from "react";
import Container from "react-bootstrap/Container";
import { AiFillPhone } from "react-icons/ai";
import { BsFillPinMapFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { GrFacebook } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { BsWhatsapp } from "react-icons/bs";

function Footer() {
  return (
    <>
      <div className="footer-container">
        <Container>
          <footer className="footer">
            <div className="footer-right col-md-4 col-sm-6">
              <h2 style={{ fontWeight: "bold", fontSize: "24px" }}>
                NEW ERA BOXING
              </h2>
              <p className="name" style={{ paddingTop: "8px" }}>
                NEW ERA BOXING C.I.C. is a registered community interest company
                (07810483)
              </p>
              <p className="about" style={{ paddingTop: "8px" }}>
                Website designed by Chris de la Bastide (For web design queries
                please email chrisdelabastide@outlook.com)
              </p>
              <a
                href="https://www.facebook.com/QueenStreetBoxing"
                aria-label="Facebook icon"
                target="_blank"
              >
                <GrFacebook
                  className="footer-icon footer-icon-interactable"
                  style={{ paddingTop: "8px" }}
                />
              </a>
              <a
                href="https://www.instagram.com/new_era_boxing/"
                aria-label="Instagram icon"
                target="_blank"
              >
                <GrInstagram
                  className="footer-icon footer-icon-interactable"
                  style={{ paddingTop: "8px" }}
                />
              </a>
              <a
                href="https://twitter.com/newera_boxing?lang=en-GB"
                aria-label="Twitter icon"
                target="_blank"
              >
                <GrTwitter
                  className="footer-icon footer-icon-interactable"
                  style={{ paddingTop: "8px" }}
                />
              </a>
              <a
                href="https://wa.me/07830085132"
                aria-label="Whatsapp icon"
                target="_blank"
              >
                <BsWhatsapp
                  className="footer-icon footer-icon-interactable"
                  style={{ paddingTop: "8px" }}
                />
              </a>
            </div>
            <div className="footer-center col-md-4 col-sm-6 footer-spacing"></div>
            <div className="footer-center col-md-4 col-sm-6 footer-spacing">
              <div className="contact-info">
                <BsFillPinMapFill
                  className="footer-icon"
                  aria-label="Pin map icon"
                />
                <p>
                  <a
                    href="https://goo.gl/maps/2SdFsigiCcKMoxnH7"
                    target="_blank"
                  >
                    <span> Unit 7b Leftwich Warehouse,</span>Queen Street,
                    Northwich, CW9 5JN
                  </a>
                </p>
              </div>
              <div className="contact-info">
                <AiFillPhone className="footer-icon" aria-label="Phone icon" />
                <p>
                  <a href="tel:07830085132">07830 085132</a>
                </p>
              </div>
              <div className="contact-info">
                <BsFillEnvelopeFill
                  className="footer-icon"
                  aria-label="Email icon"
                />
                <p>
                  <a href="mailto: neweraboxing11@gmail.com">
                    neweraboxing11@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </footer>
        </Container>
      </div>
    </>
  );
}

export { Footer };
