import { Container, Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {AiFillPhone} from "react-icons/ai"
import {BsFillPinMapFill} from "react-icons/bs"
import {BsFillEnvelopeFill} from "react-icons/bs"
import {GrFacebook} from "react-icons/gr"
import {GrInstagram} from "react-icons/gr"
import {GrTwitter} from "react-icons/gr"
import {BsWhatsapp} from "react-icons/bs"

function Contact() {
    return (
      <>
      <Container className="content">
      <h1 style={{paddingTop:'50px'}}>CONTACT US</h1>
      <p>
        If you were unable to find an answer to your question on our website, please feel free to get in touch. A member of the team will be in touch to answer your query.
      </p>
      
      <Form action="https://formsubmit.co/neweraboxing11@gmail.com" method="POST">
      {/* Honeypot */}
      <Form.Group className="mb-3" style={{display:"none"}}>
        <Form.Label>Honeypot</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Your name</Form.Label>
        <Form.Control type="name" name="Name" placeholder="Enter your name" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="Email" placeholder="Email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Your query</Form.Label>
        <Form.Control as="textarea" name="Comment" rows={5} />
      </Form.Group>

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
        <div style={{textAlign:"center"}}>
            <h3 className="padding-spacing">CONTACT INFO</h3>
            <p style={{paddingTop:"20px"}}><BsFillPinMapFill className='footer-icon' aria-label='Pin map icon'/><a href="https://goo.gl/maps/2SdFsigiCcKMoxnH7" target='_blank'>Unit 7b Leftwich Warehouse, Queen Street, Northwich, CW8 1BZ</a></p>
            <p><AiFillPhone className='footer-icon' aria-label="Phone icon"/><a href="tel:07830 085132">07830 085132</a></p>
            <p><BsFillEnvelopeFill className='footer-icon' aria-label="Email icon"/><a href= "mailto: neweraboxing11@gmail.com">neweraboxing11@gmail.com</a></p>
            
            <a href="https://www.facebook.com/QueenStreetBoxing" target="_blank"><GrFacebook className='footer-icon footer-icon-interactable' aria-label="Facebook icon" style={{paddingTop:"8px"}} /></a>
            <a href="https://www.instagram.com/new_era_boxing/" target='_blank'><GrInstagram className='footer-icon footer-icon-interactable' aria-label="Instagram icon" style={{paddingTop:"8px"}}/></a>
            <a href="https://twitter.com/newera_boxing?lang=en-GB" target='_blank'><GrTwitter className='footer-icon footer-icon-interactable' aria-label="Twitter icon" style={{paddingTop:"8px"}}/></a>
            <a href="https://wa.me/07830085132" target='_blank'><BsWhatsapp className='footer-icon footer-icon-interactable' aria-label="Whatsapp icon" style={{paddingTop:"8px"}}/></a>
        </div>
      <Row>
    </Row>

        <iframe className="google-map padding-spacing" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2386.7938417705677!2d-2.5167223841648974!3d53.25739467996046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487af9218ec4304b%3A0xbc1fad36110fa593!2sNew%20Era%20Community%20Boxing!5e0!3m2!1sen!2suk!4v1653815806918!5m2!1sen!2suk" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </Container>
      </>
    );
    
  }
  
  export {Contact};