import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import CardGroup from 'react-bootstrap/CardGroup';
import {FaQuoteLeft} from "react-icons/fa"

function Testimonials() {
    return (
      <>
        <div className='padding-spacing'></div>
        <div className='container'>
          <Container fluid="lg" className='testimonial-bg container'>
            <div className='testimonial-text'>
                <h4 className='testiomonial-title'>TESTIMONIALS</h4>
                <h2>OUR MEMBER REVIEWS</h2>
                <h6>See what out members have to say</h6>
            </div>
            <div className='padding-spacing'></div>

              <CardGroup className='CardGroup padding-spacing container testimonial-card-group'>
              <Card style={{ width: '90%' }}>
                  <Card.Body>
                      <Card.Title><FaQuoteLeft className='footer-icon speech-mark' /></Card.Title>
                      <Card.Text>
                        <p>Amazing club! Very welcoming and friendly with a winning mentality. Excellent coaches and a cracking bunch of lads to train with. Becoming a second home for me.</p>
                        <p className='testimonial-name'>- Darren</p>
                      </Card.Text>
                  </Card.Body>
                  </Card>
              <Card style={{ width: '90%' }}>
                  <Card.Body>
                  <Card.Title><FaQuoteLeft className='footer-icon speech-mark' /></Card.Title>
                      <Card.Text>
                        <p>Great local boxing club that caters for all so give it a go! Wish we had something like this when I was younger.</p>
                        <p className='testimonial-name'>- Craig</p>
                      </Card.Text>
                  </Card.Body>
              </Card>
              <Card style={{ width: '90%' }}>
                  <Card.Body>
                  <Card.Title><FaQuoteLeft className='footer-icon speech-mark' /></Card.Title>
                      <Card.Text>
                        <p>Brilliant club and great atmosphere! Gave me the push and fitness needed to join the Royal Marines. Mark is a brilliant coach! Would recommend to anyone looking to box or just simply get fit.</p>
                        <p className='testimonial-name'>- Jamie</p>
                      </Card.Text>
                        <br></br>
                  </Card.Body>
                  </Card>
              </CardGroup>
              <div className='padding-spacing'></div>
              <div className='padding-spacing'></div>
          </Container>
          </div>
      </>
      
    );
  }
  
  export default Testimonials;