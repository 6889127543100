import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';

function HeroImage(){
    return (
<Container className='hero-img-container'>
    <div className="hero-image home-hero-image" aria-label="A competitive boxer warming up by shadow boxing">
                    <div className='main-container'>
                        <div className='content-container'>
                            <div className='hero-sub-images'>
                                <div className="hero-text">
                                    <h1>NEW ERA COMMUNITY BOXING</h1>
                                    <Button href="/classes" variant="primary" className='button'><h5 style={{margin:"0"}}>VIEW OUR CLASSES</h5></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Container>
)
}

export { HeroImage };