import { Container } from "react-bootstrap";
import { CardGroup } from "react-bootstrap";
import { Card } from "react-bootstrap";

function Classes() {
  return (
    <>
      <Container className="hero-img-container">
        <div
          className="hero-image classes-hero-image"
          aria-label="Lead boxing coach preparing a boxer for competition"
        >
          <div className="main-container">
            <div className="content-container">
              <div className="hero-sub-images">
                <div className="hero-text">
                  <h1>CLASSES & PRICES</h1>
                  <p>Information on the range of classes we offer.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CardGroup lg={1} className="CardGroup padding-spacing" fluid="sm">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>BOXING CLUB SESSIONS</Card.Title>
              <Card.Text>
                <div style={{ width: "100%" }}>
                  <hr className="underline short-underline"></hr>
                </div>
                <br></br>
                <p>
                  With our regular boxing sessions there's no need to pre-book,
                  and there is no joining fee to get involved. We welcome people
                  of all skill levels, so don't worry if you have no previous
                  experience in boxing. Our group sessions are split into age
                  groups, please see more details below to find the right class
                  for you.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup lg={1} className="CardGroup padding-spacing" fluid="sm">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>CLUB SESSION PRICES</Card.Title>
              <Card.Text>
                <div style={{ width: "100%" }}>
                  <hr className="underline short-underline"></hr>
                </div>
                <br></br>
                <p>
                  PAY AS YOU GO (Price per single session) – £4 - Cadets,
                  Juniors & Youth / £5 - Adults
                </p>
                <p>or</p>
                <p>
                  PAY PR WEEK (Attend all sessions within that week) – £10
                  Cadets, Juniors and Youth (Up to 5 sessions available) / £12
                  Adults (Also 5 sessions available)
                </p>
                <p>
                  We operate a none subscription based club. This means that
                  everyone is welcome to come along to train as often as they
                  want. No direct debits, no joining fee, just pay as you train.
                  Plan to train often each week? We will fix the price for you.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup className="CardGroup padding-spacing">
          <Card className="bordered-card" style={{ width: "100%" }}>
            <Card.Img variant="top" />
            <Card.Body>
              <Card.Title>CADETS (ages 5 – 9 years)</Card.Title>
              <Card.Text>
                <h6>MONDAY & THURSDAY @ 5pm</h6>
                <div style={{ width: "100%" }}>
                  <hr className="underline"></hr>
                </div>
                <br></br>
                <p>
                  A fun based none contact boxing class suitable for all
                  children. The sessions are friendly and relaxed and focus on
                  developing the key skills of boxing. The class is led by head
                  coach Mark Bebbington and aims to make every session as
                  enjoyable as possible for his youngest members.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bordered-card" style={{ width: "100%" }}>
            <Card.Img variant="top" />
            <Card.Body>
              <Card.Title>JUNIORS (10 – 15 years)</Card.Title>
              <Card.Text>
                <h6>MONDAY, TUESDAY & THURSDAY @ 6pm</h6>
                <div style={{ width: "100%" }}>
                  <hr className="underline"></hr>
                </div>
                <br></br>
                <p>
                  A class which focuses on the key skills of boxing, the aim of
                  development here is for members to continue to develop
                  technical skills and start to understand tactical aspects of
                  this boxing. It is at this stage where young members will also
                  work on improving physical fitness and factors such as balance
                  and coordination.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="bordered-card" style={{ width: "100%" }}>
            <Card.Img variant="top" />
            <Card.Body>
              <Card.Title>ADULTS (16+ years)</Card.Title>
              <Card.Text>
                <h6>MONDAY, TUESDAY & THURSDAY @ 7pm</h6>
                <div style={{ width: "100%" }}>
                  <hr className="underline"></hr>
                </div>
                <br></br>
                <p>
                  A class which still focuses on the key skills of boxing, the
                  aim of development here is for members to continue to develop
                  technical skills and start to understand tactical aspects of
                  this boxing. It is at this stage where young members will also
                  work on improving physical fitness and factors such as balance
                  and coordination.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup lg={1} className="CardGroup padding-spacing" fluid="sm">
          <Card style={{ width: "100%" }} className="bordered-card">
            <Card.Body>
              <Card.Title>PERSONAL TRAINING & GROUP TRAINING</Card.Title>
              <div style={{ width: "100%" }}>
                <hr className="underline short-underline"></hr>
              </div>
              <br></br>
              <Card.Text>
                <p>
                  Outside of our regular boxing sessions, we also offer personal
                  1-2-1 training and private group training sessions. For more
                  information, please contact us{" "}
                  <a href="contact" className="click-here">
                    here
                  </a>
                  .
                </p>
                <p>
                  PERSONAL TRAINING PRICES - £25 per hour OR 10 personal
                  training sessions for £225.
                </p>
                <p>
                  GROUP TRAINING PRICES - per session: £30 for 2 people / £36
                  for 3 people / £40 for 4+ people
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </Container>
    </>
  );
}

export { Classes };
